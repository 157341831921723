import {
  Injectable,
  ViewChild,
  Inject,
  AfterViewInit,
  OnDestroy,
  TemplateRef,
  NgZone,
} from '@angular/core';
import { MessageComponent } from '../components/_layout/messages/message/message.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { appSettings } from '../app.settings';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Injectable()
export class LxmMessage {
  public defaultDuration = 1500; //ms

  constructor(
    private _snackBar: MatSnackBar,
    private _snackBarRef: MatSnackBarRef<MessageComponent>,
    private _translateService: TranslateService,
    private zone: NgZone,
  ) {}

  public translate(key: string) {
    return this._translateService.instant(key);
  }

  public openMessage(
    options: IMessageOptions,
    messageType = MessageType.notification,
  ) {
    return this.zone.run((_) => {
      const message = options.message || null;
      const messages = options.messages || null;
      const template = options.template || null;
      const duration = options.duration || this.defaultDuration;

      if (message || messages || template) {
        return this._snackBar.openFromComponent(MessageComponent, {
          duration: options.indefinite ? undefined : duration,
          data: {
            message: message,
            args: options.args,
            messages: messages,
            template: template,
            buttonText: options.buttonText,
            buttonIcon: options.buttonIcon,
            buttonAction: options.buttonAction,
          },
          verticalPosition: 'bottom',
          horizontalPosition: 'end',
          panelClass: messageType,
        });
      }

      return null;
    });
  }

  public ok(options: IMessageOptions) {
    return this.openMessage(options, MessageType.success);
  }

  public error(options: IMessageOptions) {
    return this.openMessage(options, MessageType.error);
  }

  public notify(options: IMessageOptions) {
    return this.openMessage(options, MessageType.notification);
  }

  public warning(options: IMessageOptions) {
    return this.openMessage(options, MessageType.warning);
  }
}

export interface IMessageOptions {
  message?: string;
  args?: any[];
  messages?: string[];
  template?: string;
  duration?: number;
  indefinite?: boolean;
  buttonIcon?: string;
  buttonText?: string;
  buttonAction?: () => void;
}

enum MessageType {
  success = 'success-message',
  error = 'error-message',
  notification = 'notification-message',
  warning = 'warning-message',
}

<lxm-dialog [dialogRef]="dialogRef">
  <span header-title> {{data.title | translate}} </span>

  <div *ngIf="data.image" header-image class="{{data.image}}"></div>

  <div body>
    <div>{{data.message | translate}}</div>
    <div *ngIf="data.template" [innerHTML]="data.template"></div>
    <ng-container *ngTemplateOutlet="templateRef"> </ng-container>
  </div>

  <div footer [ngSwitch]="swapButtons" cdkFocusRegionstart>
    <ng-container *ngSwitchCase="true">
      <button
        appButton
        buttonType="button"
        class="mr10"
        [label]="data.no"
        (click)="onDecline()"
        *ngIf="data.showNo"
      ></button>
      <button
        appButton
        buttonType="submit"
        [label]="data.yes"
        [mat-dialog-close]="true"
        cdkFocusInitial
        tabindex="1"
        *ngIf="data.showYes"
      ></button>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <button
        appButton
        buttonType="submit"
        class="mr10"
        [label]="data.yes"
        [mat-dialog-close]="true"
        cdkFocusInitial
        tabindex="1"
        *ngIf="data.showYes"
      ></button>
      <button
        appButton
        buttonType="button"
        [label]="data.no"
        (click)="onDecline()"
        *ngIf="data.showNo"
      ></button>
    </ng-container>
  </div>
</lxm-dialog>

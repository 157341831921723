import { Injectable, OnDestroy } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';

import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError,
} from '@angular/router';

export var currentRoute: string = window.location.pathname;
export var previousRoute: string = currentRoute;

@Injectable({ providedIn: 'root' })
export class RouteListener implements OnDestroy {
  private _routeListener: Subscription;

  constructor(private router: Router) {}

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationEnd) {
      previousRoute = currentRoute;
      currentRoute = event.urlAfterRedirects || event.url;
      //console.log(previousRoute + " --> " +  currentRoute);
    }
  }

  public initializeListener() {
    if (this._routeListener) {
      this._routeListener.unsubscribe();
    }
    this._routeListener = this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  public destroyListener() {
    this._routeListener.unsubscribe();
  }

  public ngOnDestroy() {
    this.destroyListener();
  }
}

@Injectable({ providedIn: 'root' })
export class RouteNavigator {
  constructor(private _router: Router) {}

  public toRoute(route: string, path: any) {
    this._router.navigate([route], path);
  }

  public toPreviousRoute(options: IPreviousRouteOptions) {
    let fallback = options.fallback || '/';

    if (!previousRoute || currentRoute === previousRoute) {
      return this._router.navigate([fallback]);
    }

    return this._router.navigate([previousRoute]);
  }
}

export interface IPreviousRouteOptions {
  fallback: string;
}

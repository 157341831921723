import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ViewEncapsulation } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { ButtonComponent } from 'src/app/components/form/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [ButtonComponent, TranslateModule, NgIf, NgFor],
})
export class MessageComponent {
  constructor(
    public snackBar: MatSnackBar,
    public snackBarRef: MatSnackBarRef<MessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
  ) {}

  public dismiss() {
    this.snackBar.dismiss();
  }
}

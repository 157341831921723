<!-- <form class="dialog-container">
  <section class="dialog-header">
    <div class="dialog-title">
      <ng-content select="[header-title]"></ng-content>
    </div>
    <ng-content select="[header-image]"></ng-content>
    <div class="dialog-actions">
      <ng-content select="[header-actions]"></ng-content>
      <button
        appButton
        color="transparent"
        icon="close"
        (click)="closeDialog()"
      ></button>
    </div>
  </section>

  <section class="dialog-content" [class.no-margin]="disableContentMargin">
    <ng-content select="[body]"></ng-content>
  </section>

  <section class="dialog-footer">
    <ng-content select="[footer]"></ng-content>
  </section>

</form> -->

<ov-header></ov-header>

<app-overlay-view></app-overlay-view>

import { Component, OnInit, Inject, Input, HostListener } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
} from '@angular/material/dialog';
import { KeyCode } from 'src/app/enum/key-code';
import { IConfirmOptions } from 'src/app/helpers/dialogs';
import { ButtonComponent } from '../../form/button/button.component';
import {
  NgIf,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DialogComponent } from '../dialog/dialog.component';

@Component({
  selector: 'confirmation-dialog',
  host: { class: 'dialog' },
  templateUrl: './confirm.dialog.html',
  standalone: true,
  imports: [
    DialogComponent,
    ButtonComponent,
    TranslateModule,
    MatDialogClose,
    NgSwitch,
    NgSwitchCase,
    NgIf,
    NgTemplateOutlet,
  ],
  providers: [],
})
export class ConfirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmOptions,
  ) {}

  @HostListener('window:keydown', ['$event']) onKeyDown(e) {
    if (e.keyCode === KeyCode.ENTER) {
      if (document.activeElement.tagName == 'MAT-DIALOG-CONTAINER') {
        this.onConfirm();
      }
    }
  }

  public get swapButtons() {
    return this.data?.swapButtons ? true : false;
  }

  public get templateRef() {
    return this.data?.templateRef;
  }

  public onDecline() {
    this.dialogRef.close(false);
  }

  public onConfirm() {
    this.dialogRef.close(true);
  }
}

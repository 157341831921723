<div class="lxm-message-container">
  <span class="message-indicator"></span>

  <div class="message">
    <div class="message-data">
      {{ data.message | translate: data.args }}

      <div *ngIf="data.template" [innerHTML]="data.template"></div>

      <!--<ng-container *ngTemplateOutlet="template"></ng-container>-->

      <div *ngIf="data.messages">
        <span *ngFor="let message of data.messages">
          {{ message | translate }}
        </span>
      </div>
    </div>

    <button
      appButton
      class="message-close"
      color="transparent"
      icon="close"
      (click)="dismiss()"
    ></button>
  </div>

  <div *ngIf="data.buttonAction" class="action-container">
    <button
      appButton
      type="beige"
      [icon]="data.buttonIcon"
      [label]="data.buttonText"
      (click)="data.buttonAction()"
    ></button>
  </div>
</div>
